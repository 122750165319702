function activeUser(userID) {

  let url = `/active-user/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.activeUser = activeUser;

function deleteUser(userID) {
  const formData = new FormData();
  formData.append("subject", "user");
  formData.append("action", "delete");

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = "/users";
    }

    console.log(response.status);
  });
}

window.deleteUser = deleteUser;

function deleteUserEmail(userID) {
  const formData = new FormData();
  formData.append("subject", "email");
  formData.append("action", "delete");

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.deleteUserEmail = deleteUserEmail;

function deleteUserPhone(userID) {
  const formData = new FormData();
  formData.append("subject", "phone");
  formData.append("action", "delete");

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.deleteUserPhone = deleteUserPhone;

function deleteAddress(userID) {
  const formData = new FormData();
  formData.append("subject", "address");
  formData.append("action", "delete");

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.deleteAddress = deleteAddress;

function deleteAccount(userID) {
  const formData = new FormData();
  formData.append("subject", "account");
  formData.append("action", "delete");

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.deleteAccount = deleteAccount;

function deleteCard(userID) {
  const formData = new FormData();
  formData.append("subject", "card");
  formData.append("action", "delete");

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.deleteCard = deleteCard;

function deleteWifi(userID) {
  const formData = new FormData();
  formData.append("subject", "wifi");
  formData.append("action", "delete");

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.deleteWifi = deleteWifi;

function deleteDoor(userID, doorID) {
  const formData = new FormData();
  formData.append("subject", "door");
  formData.append("action", "delete");
  formData.append("door", doorID);

  let url = `/users/${userID}`;
  fetch(url, {
    method: "POST",
    redirect: "follow",
    credentials: "include",
    body: formData,
  }).then(function (response) {
    if (response.redirected) {
      window.location.href = response.url;
    }

    console.log(response.status);
  });
}

window.deleteDoor = deleteDoor;

function showWifiPassword() {
  const x = document.querySelector("#wifiPasswordID");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}

window.showWifiPassword = showWifiPassword;
