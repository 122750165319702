
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

function  loadCalendar() {
    document.addEventListener('DOMContentLoaded', function() {
        var calendarEl = document.getElementById('calendar');

        var calendar = new Calendar(calendarEl, {
            plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
            navLinks: true, // can click day/week names to navigate views
            editable: true,
            events: [
                {
                    title: 'All Day Event',
                    start: '2020-09-01',
                },
                {
                    title: 'Long Event',
                    start: '2020-09-07',
                    end: '2020-09-10'
                },
                {
                    id: 999,
                    title: 'Repeating Event',
                    start: '2020-09-09T16:00:00'
                },
                {
                    id: 999,
                    title: 'Repeating Event',
                    start: '2020-09-16T16:00:00'
                },
                {
                    title: 'Conference',
                    start: '2020-09-11',
                    end: '2020-09-13'
                },
                {
                    title: 'Meeting',
                    start: '2020-09-12T10:30:00',
                    end: '2020-09-12T12:30:00'
                },
                {
                    title: 'Lunch',
                    start: '2020-09-12T12:00:00'
                },
                {
                    title: 'Meeting',
                    start: '2020-09-12T14:30:00'
                },
                {
                    title: 'Happy Hour',
                    start: '2020-09-12T17:30:00'
                },
                {
                    title: 'Dinner',
                    start: '2020-09-12T20:00:00'
                },
                {
                    title: 'Birthday Party',
                    start: '2020-09-13T07:00:00'
                },
                {
                    title: 'Click for Google',
                    url: 'http://google.com/',
                    start: '2020-09-28'
                }
            ]
        });

        calendar.render();
    });

}

window.loadCalendar = loadCalendar;
