import 'bootstrap';
import 'bootstrap-4-grid';

import './custom.scss';
import './signin.css';
import './stripe.css';

import './validation.js';
import './stripe.js';
import './calendar.js';
import './user.js';
